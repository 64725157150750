/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

#runners {
    background-image: url("images/runners-sm.png");
    box-shadow: inset 0px 0px 50px 50px #212529;
    width: 90%;
    height: 500px;
}

.header {
    font-size: 4em;
}

.subheader {
    font-size: 2em;
}

.textHighlight {
    font-size: 25px;
}

#footer {
    background-color: #14171A;
    width: 100%;
}

.ft-link {
    text-decoration: none;
    color: #F8F9FA;
}

a.ft-link:hover {
    text-decoration: underline;
}

.ft-img {
    width: 50px;
    height: 50px;
}

.it {
    font-style: italic;
}

.icon {
    width: 60px;
    height: 60px;
    color: #FACA18;
    border: 1px solid #FACA18;
    border-radius: 50%;
    font-size: 30px;
    line-height: 60px;
}

.vid-section {
    display: block;
}

.iframe-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 75%; /* 4:3 aspect ratio */
}

.tw-container {
    width: 100%;
    height: auto;
}

.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

@media(min-width: 550px) {
    #runners {
        background-image: url("images/runners-md.png");
        height: 600px;
    }
    .tw-container {
        width: 400px;
    }
}


@media(min-width: 1000px) {
    #runners {
        background-image: url("images/runners.png");
        height: 600px;
    }
    .vid-section {
        display: inline;
    }
}

@media(min-width: 1200px) {
    .header {
        font-size: 6em;
    }
    .subheader {
        font-size: 3em;
    }
    .textHighlight {
        font-size: 2em;
    }
    .text {
        font-size: 20px;
    }
    .icon {
        width: 80px;
        height: 80px;
        color: #FACA18;
        border: 1px solid #FACA18;
        border-radius: 50%;
        font-size: 40px;
        line-height: 80px;
    }
}

/*bootstrap dark color: #212529*/


